const colorsHex = {
  aquaDark: '#004651', //Aqua/Dark
  aquaDefault: '#01838C', //Aqua/Default
  aquaLight: '#00B0B9', //Aqua/Light
  aquaDefault60: '#67B5BA', //Tints/Aqua/Default/60
  aquaDefault40: '#99CDD1', //Tints/Aqua/Default/40
  aquaDefault20: '#CCE6E8', //Tints/Aqua/Default/20
  aquaDefault5: '#E6EDEE', //Tints/Aqua/Default/5
  warmGrey200: '#E8E6E2',
  warmGrey100: '#F0EEEA',
  neutralGrey: '#BDBDBD',
  white: '#FFF',
  black: '#000',
  red: '#E40571',
  gradientStart: '#55afb8',
  gradientEnd: '#1f2960',
};

export const brandColors = {
  greenDefault: '#02cd86',
  greenDark: '#016a45',
  greenLight: '#ccf5e7',
};

export default colorsHex;
