'use client';

import { createTheme } from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import { Roboto_Slab, Source_Sans_3 } from 'next/font/google';
import colorsHex from '../palette/colors';
import { fontSizeVars, spacingVars } from '../themeVars.css';
import './muiOverrides.d.ts';

const robotoSlab = Roboto_Slab({ subsets: ['latin'], weight: ['300', '400', '700'] });
const sourceSans = Source_Sans_3({ subsets: ['latin'], weight: ['400', '600'] });

// const headingFontFamily = '"Roboto Slab", serif';
export const headingFontWeight = 300;
export const headingFontWeightBold = 700;
export const bodyFontWeight = 300;
export const bodyFontWeightBold = 600;

export const headingLineHeightSm = 0.96;
export const headingLineHeight = 1;

// fontSizeVars
const typography: TypographyOptions = {
  poster: {
    fontSize: fontSizeVars.poster, // pxToRem(400),
    ...robotoSlab.style,
    fontWeight: 300,
    lineHeight: headingLineHeightSm,
  },
  h1: {
    fontSize: fontSizeVars.h1, // pxToRem(152),
    ...robotoSlab.style,
    fontWeight: headingFontWeight,
    lineHeight: headingLineHeightSm,
  },
  h2: {
    fontSize: fontSizeVars.h2, // pxToRem(96),
    ...robotoSlab.style,
    lineHeight: headingLineHeight,
    // fontWeight: headingFontWeight,
  },
  h3: {
    fontSize: fontSizeVars.h3, // pxToRem(80),
    ...robotoSlab.style,
    fontWeight: headingFontWeight,
    lineHeight: headingLineHeight,
  },
  h4: {
    fontSize: fontSizeVars.h4, // pxToRem(64),
    ...robotoSlab.style,
    fontWeight: headingFontWeight,
    lineHeight: headingLineHeight,
  },
  h5: {
    fontSize: fontSizeVars.h5, // pxToRem(40),
    ...robotoSlab.style,
    fontWeight: headingFontWeight,
    lineHeight: headingLineHeight,
  },
  h6: {
    fontSize: fontSizeVars.h6, // pxToRem(24),
    ...robotoSlab.style,
    fontWeight: headingFontWeight,
    lineHeight: headingLineHeight,
  },
  body: {
    fontSize: fontSizeVars.body, // pxToRem(28),
    ...sourceSans.style,
  },
  large: {
    fontSize: fontSizeVars.large, // pxToRem(40),
    ...sourceSans.style,
  },
  small: {
    fontSize: fontSizeVars.small, // pxToRem(24),
    ...sourceSans.style,
  },
  tiny: {
    fontSize: fontSizeVars.tiny, // pxToRem(16),
    ...sourceSans.style,
  },
  button: {
    fontSize: fontSizeVars.button, // pxToRem(24),
    ...sourceSans.style,
    fontWeight: 600,
  },
};

const theme = createTheme({
  typography,
  palette: {
    primary: {
      main: colorsHex.aquaDefault,
      light: colorsHex.aquaLight,
      dark: colorsHex.aquaDark,
    },
    secondary: {
      main: colorsHex.aquaDefault40,
      light: colorsHex.aquaDefault20,
      dark: colorsHex.aquaDefault60,
    },
    text: {
      primary: colorsHex.aquaDark,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        color: 'primary',
        variant: 'contained',
        disableElevation: true,
      },
      styleOverrides: {
        root: ({ ownerState }) => {
          const { size = 'medium' } = ownerState;

          return {
            lineHeight: 1,
            ...typography.button,
            ...(size === 'small' && {
              padding: [spacingVars['12'], spacingVars['24']].join(' '),
              fontSize: fontSizeVars.buttonSm,
            }),
            ...(size === 'medium' && {
              padding: [spacingVars['16'], spacingVars['32']].join(' '),
            }),
            textTransform: 'none',
            borderRadius: 999,
          };
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        maxWidth: 'lg',
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          'textTransform': 'none',
          'color': colorsHex.aquaDark,
          'backgroundColor': colorsHex.aquaDefault40,
          '&.Mui-selected': {
            color: colorsHex.warmGrey100,
            backgroundColor: colorsHex.aquaDefault,
          },
        }),
      },
    },
    MuiTypography: {
      defaultProps: {
        variant: 'body',
      },
    },
    MuiSvgIcon: {
      defaultProps: {
        fontSize: 'inherit',
      },
    },
  },
});

export default theme;

export { typography as muiThemeTypography };
